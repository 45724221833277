import { GLOBAL_CONFIG } from 'src/app/configs/global.config';

export const environment = {
  production: false,
  api_url: 'https://gw.cube.rina.org/diassteeldecarbon-func/test',
  admin_tool_url: 'https://gw.cube.rina.org/admin-tool',
  ...GLOBAL_CONFIG,
  powerbi: {
    groupId: 'd4092f74-b6c3-4e5a-8d83-7d22f026f83c',
    reportId: '781d95eb-c659-46e4-ad7d-f2bed5cc44a9',
  },
  b2cPolicies: {
    clientId: '33e5456b-20ca-4cf6-b504-2a82fc74bf6d',
    tenantId: 'ab37f843-3ed8-42e4-b104-6929068b10db',
    redirectUri: 'https://co2neutral-test.cube.rina.org',
    redirectCourtesyPageUri: 'https://www.rina.org/',
    postLogoutRedirectUri: 'https://www.rina.org/',
    tenant : 'rinagroupb2c',
    names: {
      signUpSignIn: 'B2C_1A_SIGNUP_SIGNIN',
      resetPassword: 'B2C_1A_PASSWORDRESET',
    },
    authorities: {
      signUpSignIn: {
        authority: 'https://login.rina.org/rinagroupb2c.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN',
      },
      resetPassword: {
        authority: 'https://login.rina.org/rinagroupb2c.onmicrosoft.com/B2C_1A_PASSWORDRESET',
      },
      editProfile: {
        authority: 'https://login.rina.org/rinagroupb2c.onmicrosoft.com/B2C_1A_PROFILEEDIT',
      },
    },
    domain: 'https://login.rina.org/',
    subdomain: 'rinagroupb2c.onmicrosoft.com',
    authorityDomain: 'https://login.rina.org/rinagroupb2c.onmicrosoft.com',
  },
  localStorageKeyPrefix: 'cube-mt-test',
};
